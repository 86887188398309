import React, { useEffect, useState } from "react";
import AvatarCard from "./AvatarCard";
import useTranslations from "../../hooks/useTranslations";

const AvatarList = ({ title, avatars, fetchAvatars, handleCreate }) => {
  const [query, setQuery] = useState("");
  const translations = useTranslations();

  useEffect(() => {
    if (query !== "") {
      fetchAvatars(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const renderAvatars = () => {
    if (Array.isArray(avatars)) {
      if (avatars.length === 0) {
        return <p>{translations.avatars.empty}</p>;
      }

      return (
        <div className="row">
          {avatars
            .filter((avatar) => !avatar.super_fetch_assistant)
            .map((avatar) => (
              <div key={avatar.avatar_id} className="col-12 col-md-6 col-xl-4">
                <AvatarCard avatar={avatar} />
              </div>
            ))}
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid px-0 pb-4">
      <div className="row mb-3 align-items-center">
        <div className="col-8">
          <h1 className="text-capitalize text-gradient d-inline-block">
            {title}
          </h1>
        </div>
        <div className="col-4 text-end">
          <button
            onClick={handleCreate}
            className="btn btn-primary me-0 ms-auto"
          >
            <i className="fa fa-plus"></i>{" "}
            <span className="hide-mobile">Avatar</span>
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <input
            type="text"
            value={query}
            className="form-control mb-3 "
            placeholder={translations.avatars.search}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </div>

      {renderAvatars()}
    </div>
  );
};

export default AvatarList;
