import { AppConfigContext } from "../context/AppConfigContext";
import { TranslationsContext } from "../context/TranslationsContext";
import React, { useContext, useEffect } from "react";
import parse from "html-react-parser";
import { Link } from "@reach/router";
import { getValue } from "../utils";
import "../css/landing.css";

const LandingSpanish = () => {
  const appconfig = useContext(AppConfigContext);
  const { setLang } = useContext(TranslationsContext);
  const { landing_bg_className, landing_text_className } = appconfig;

  useEffect(() => {
    document.title = "Contenido Auténtico 10X Más Rápido con IA";
    setLang("es");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTextValue = (key) => {
    const value = getValue(appconfig, key);
    if (value && value !== null) {
      if (String(value).includes("<")) {
        return parse(value);
      }
      return value;
    }
  };

  return (
    <div
      id="landing"
      className={`container-fluid py-5 ${landing_bg_className}`}
    >
      <div className="container position-relative">
        <nav
          id="navbar"
          className={`navbar px-3 br-10 w-100 my-4 align-items-center mb-2 py-2 shadow ${landing_bg_className}`}
        >
          <Link to="/auth" className="navbar-brand">
            <img
              alt="Bemodo Bunny"
              className="d-inline-block"
              src={getValue(appconfig, "landing_logo_src")}
              style={{ height: 60, width: "auto", objectFit: "contain" }}
            />{" "}
          </Link>
          <Link to="/auth" className="btn btn-primary">
            Comenzar
          </Link>
        </nav>
        <div className="row hero-section align-items-center">
          <div className="col-12 col-md-12 col-lg-7 mb-3">
            <h1 className={`${landing_text_className} display-2 bold`}>
              Contenido Auténtico
              <span class="text-gradient display-2">10X Más Rápido</span>
            </h1>
            <p className={`${landing_text_className} h3 fw-normal`}>
              El Copywriting con IA está revolucionando la forma en que creamos
              contenido. Bemodo IA puede crear contenido para blogs, sitios web,
              redes sociales y más.
            </p>
            <Link to="/auth" className="btn btn-lg px-5 py-2 mt-3 btn-primary">
              Comenzar
            </Link>
          </div>
          <div className="col-12 col-md-12 col-lg-5 px-0 mb-3 position-relative">
            <img
              src={getValue(appconfig, "hero_img")}
              className="mw-100 w-100 d-block m-auto"
              alt="bemodo ai wizard"
            />
          </div>
        </div>
      </div>

      {getValue(appconfig, "about") && (
        <div className="container mb-4">
          <div className={`card p-4 text-large ${landing_bg_className}`}>
            <h2 className={`${landing_text_className}`}>About Us</h2>
            <p className={`${landing_text_className}`}>
              {renderTextValue("about")}
            </p>
            <p className={`${landing_text_className}`}>
              {renderTextValue("call_to_action")}
            </p>
          </div>
        </div>
      )}

      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-4">
            <div className={`card mb-3 p-4 shadow ${landing_bg_className}`}>
              <div className="card-body p-4">
                <i className="fas fa-2x text-gradient mb-4 fa-shapes"></i>
                <h3 className={`${landing_text_className} bold`}>
                  Elige una Plantilla
                </h3>
                <p className={`${landing_text_className}`}>
                  Elige una plantilla de nuestro amplio catálogo. Busca y filtra
                  por tipo de contenido y etiquetas.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-4">
            <div className={`card mb-3 p-4 shadow ${landing_bg_className}`}>
              <div className="card-body p-4">
                <i className="fas fa-2x text-gradient mb-4 fa-magic"></i>
                <h3 className={`${landing_text_className} bold`}>
                  Mejora tu Contenido
                </h3>
                <p className={`${landing_text_className}`}>
                  No tienes que ser un experto de IA. Haz tu contenido 10X mejor
                  con un solo click.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-4">
            <div className={`card mb-3 p-4 shadow ${landing_bg_className}`}>
              <div className="card-body p-4">
                <i className="fas fa-2x text-gradient mb-4 fa-shapes"></i>
                <h3 className={`${landing_text_className} bold`}>
                  Tutoriales en Video
                </h3>
                <p className={`${landing_text_className}`}>
                  Aprende a tu velocidad con nuestras capacitaciones disponibles
                  24/7 en video.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingSpanish;
