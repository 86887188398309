import React, { useContext, useRef, useState } from "react";
import { AvatarsContext } from "../../context/AvatarsContext";
import useTranslations from "../../hooks/useTranslations";

const AvatarInputFiles = () => {
  const [validInput, setValidInput] = useState(false);
  const inputRef = useRef();

  const translations = useTranslations();

  const { avatar, saveAvatarFile, setPropertyAvatar, spinner } = useContext(AvatarsContext);


  const handleUpload = async () => {
    if(validInput) {
      inputRef.current.value = null;
      await saveAvatarFile(avatar?.file, avatar.assistant_id, avatar.avatar_id);
    } 
  }

  const handleChangeFile = (event) => {
    const file = event.target.files[0];
    setPropertyAvatar("file", file);

    if(event.target.files.length > 0) {
      setValidInput(true);
    } else {
      setValidInput(false);
    }
  }

  const renderIcon = () => {
    if(spinner) {
      return <div className="spinner-border position-absolute top-0 bottom-0 
      end-0 m-auto me-3"></div>
    } else {
      return (
        <i 
          className="position-absolute top-0 bottom-0 
          end-0 fas fa-save fs-4 m-auto me-3"
          style={{
            height: 'max-content'
          }}
        />
      )
    }
  }

  return (
    <div className="row mt-5" style={{height: '123px'}}>
      <div className="px-0">
        <label className="form-label">
          <b>{translations.general.insert_files}</b>
        </label>
        <span className="small d-block"> <span className="bold small">{translations.general.file_types}:</span> .docx, .pdf, .pptx, .txt</span>
        <input 
          accept=".docx, .pdf, .pptx, .txt"
          ref={inputRef}
          disabled={spinner}
          className="form-control rounded-3" 
          type="file" 
          id="avatar__input-file"
          onChange={handleChangeFile}
          style={{
            paddingLeft: '0px!important'
          }}
        />
      </div>

      <div className="col-12 px-0 mt-4">
        <div className="col-12 col-md-5 px-0">
          <button
            className="btn btn-primary w-100 position-relative"
            onClick={handleUpload}
            type="button"
            disabled={spinner}
          >
            {translations.general.upload}
            {renderIcon()}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AvatarInputFiles;
