import React, { useContext } from "react";
import useTemplateInputs from "../../hooks/templates/useTemplateInputs";
import useValidateInputs from "../../hooks/templates/useValidateInputs";
import useTemplatePrompt from "../../hooks/templates/useTemplatePrompt";
import { AuthContext } from "../../context/AuthContext";
import SuperFetchService from "../../services/SuperFetchService";
import { DocumentsContext } from "../../context/DocumentsContext";
import LoadingCard from "../global/LoadingCard";

const TemplateAsideApply = ({ template }) => {
  const { renderTemplateFields } = useTemplateInputs();
  const { validateFields } = useValidateInputs();
  const getTemplatePrompt = useTemplatePrompt();

  const { user } = useContext(AuthContext);
  const { setLoadingOutputs, loadingOutputs } = useContext(DocumentsContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleGenerate();
  }

  const handleGenerate = () => {
    const validFields = validateFields();

    if (validFields) {
      setLoadingOutputs(true);
      getOutput();
    }
  }

  const getOutput = () => {
    const currentPrompt = getTemplatePrompt();

    SuperFetchService.getOutput({
      messages: [currentPrompt],
      assistant_id: user.defaultAssistant.id
    });
  }

  const renderLoading = () => {
    if(loadingOutputs){
      return(
        <LoadingCard/>
      )
    }
  }

  return (
    <form onSubmit={handleSubmit} className="container px-0 mt-4">
      <h3 className="text-muted ">{template?.name}</h3>
      <p className="text-muted">{template?.description}</p>

      <div className="row">
        <div className="col-12 px-0">
          {renderTemplateFields('bg-white')}
        </div>
      </div>

      <div className="row mt-3">
        {renderLoading()}
        <div className="col-6 px-0">
          <button
            type="submit"
            disabled={loadingOutputs}
            className="btn btn-primary w-100"
          >
            Generate
          </button>
        </div>
      </div>
    </form>
  );
};

export default TemplateAsideApply;
