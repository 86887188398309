import React, { useReducer, createContext, useContext } from "react";
import MessagesReducer from "../reducers/MessagesReducer";
import {
  SET_PROPERTY_MESSAGE,
  MESSAGES_RECEIVED,
  CREATE_MESSAGE,
  APPEND_MESSAGE,
  SET_MESSAGE,
  CLEAR_MESSAGES,
  ENHANCED_RECEIVED,
  SET_ENHANCING,
  SET_PROMPT,
  SET_PROPERTY_MESSAGE_PLURAL,
  SAVE_MESSAGE,
  APPEND_ENHANCED_TOKEN,
  SET_GENERATING,
  SET_RUNING_THREAD,
  SET_THREAD_MESSAGES,
  APPEND_THREAD_MESSAGE
} from "../types/messages";
import MessagesService from "../services/MessagesService";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import useTranslations from "../hooks/useTranslations";
import { SET_CONVERSATION } from "../types/conversations";
import { OrganizationsContext } from "./OrganizationsContext";

const initialState = {
  conversation: null,
  generating: false,
  runingThread: false,
  enhancing: false,
  message_id: null,
  spinner: false,
  messages: null,
  message: null,
  prompt: "",
  max: null,
  threadMessages: []
};

export const MessagesContext = createContext(initialState);

export const MessagesProvider = ({ children }) => {
  const translations = useTranslations();

  const [state, dispatch] = useReducer(MessagesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);
  const { organization } = useContext(OrganizationsContext);


  const handleError = (error) => {
    dispatch({ type: HIDE_SPINNER });
    if (error.response) {
      if (error.response.data) {
        if (error.response.data.error) {
          if (error.response.data.error.code) {
            return alert(`Open AI Error: ${error.response.data.error.message}`);
          }
        }
      }
      if (error.response.status === 412) {
        clearModal();
        return navigate("/sorry");
      }
    }
    alert(error);
  };

  const getMessages = (filters) => {
    MessagesService.getMessages(filters).then((res) => {
      const { messages, max } = res.data;
      dispatch({ type: MESSAGES_RECEIVED, payload: { messages, max } });
    });
  };

  const getConversationMessages = (conversation_id, filters) => {
    dispatch({ type: SHOW_SPINNER })
    MessagesService.getConversationMessages(conversation_id, filters).then(
      (res) => {
        dispatch({ type: HIDE_SPINNER })
        const { messages, max } = res.data;
        dispatch({ type: MESSAGES_RECEIVED, payload: { messages, max } });
      }
    ).catch(error => {
      dispatch({ type: HIDE_SPINNER });
    })
  };

  const getThreadMessages = (assistant_id) => {
    MessagesService.getThreadMessages(assistant_id).then(
      (res) => {
        dispatch({ type: SET_THREAD_MESSAGES, payload: res.data });
      }
    );
  }

  const appendMessage = (message) => {
    dispatch({ type: APPEND_MESSAGE, payload: message });
  };

  const setEnhanced = (message) => {
    dispatch({ type: ENHANCED_RECEIVED, payload: message });
  };

  const setEnhancing = (payload) => {
    dispatch({ type: SET_ENHANCING, payload });
  };

  const setGenerating = (payload) => {
    dispatch({ type: SET_GENERATING, payload: payload });
  };

  const setRuningThread = (payload) => {
    dispatch({ type: SET_RUNING_THREAD, payload: payload });
  };

  const setConversation = (conversation) => {
    dispatch({ type: SET_CONVERSATION, payload: conversation });
  };

  const setThreadMessages = (messages) => {
    dispatch({ type: SET_THREAD_MESSAGES, payload: messages });
  };

  const appendThreadMessage = (message) => {
    dispatch({ type: APPEND_THREAD_MESSAGE, payload: message });
  }

  const saveMessage = (message, callback) => {

    let service = MessagesService.putMessage;

    if (isNaN(parseInt(message.message_id))) {
      service = MessagesService.postMessage;
      dispatch({ type: SET_GENERATING, payload: true });
    } else {
      console.log('update message');
      
    }

    if (message.avatarMessage) {
      dispatch({ type: SET_RUNING_THREAD, payload: true });
      if (!message.deleteUserMsg) appendThreadMessage(message.content);
      if (state.threadMessages !== null && state.threadMessages.length > 0) {
        message.thread_id = state.threadMessages[state.threadMessages.length - 1].thread_id;
      }
    }

    if (organization && organization.organization_id) {
      message.organization_id = organization.organization_id;
    }

    service(message)
      .then((res) => {
        if (typeof callback === "function") {
          callback(res.data.message);
        }
        if (res.data.message) {
          dispatch({ type: APPEND_MESSAGE, payload: res.data.message });
        }
      })
      .catch(handleError);
  };

  const getPromptResponse = (message, callback) => {

    let service = MessagesService.putMessage;
    if (isNaN(parseInt(message.message_id))) {
      service = MessagesService.postMessage;
      dispatch({ type: SET_GENERATING, payload: true });
    }

    if (
      organization.organization_id
      && organization.organization_id !== undefined
    ) {
      message.organization_id = organization.organization_id;
    }

    service(message)
      .then(async (res) => {
        if (!message.show_prompt) await deleteMessage(res.data.message.message_id);

        return res;
      })
      .then((res) => {
        if (typeof callback === "function") {
          callback(res.message);
        }
      })
      .catch(handleError);
  };

  const createMessage = () => {
    dispatch({ type: CREATE_MESSAGE });
  };

  const setMessage = (message) => {
    dispatch({ type: SET_MESSAGE, payload: message });
  };

  const setPropertyMessage = (key, value) => {
    dispatch({ type: SET_PROPERTY_MESSAGE, payload: { key, value } });
  };

  const setPropertyMessagePlural = (message_id, key, value) => {
    dispatch({
      type: SET_PROPERTY_MESSAGE_PLURAL,
      payload: { message_id, key, value },
    });
  };

  const appendEnhancedToken = (value) => {
    dispatch({
      type: APPEND_ENHANCED_TOKEN,
      payload: value,
    });
  };

  const deleteMessage = (message_id, showSuccess) => {
    return new Promise((resolve, reject) => {
      MessagesService.deleteMessage(message_id)
        .then(() => {
          if (showSuccess) success(translations.messages.deleted);

          resolve();
        })
        .catch((err) => {
          handleError(err);
          reject(err);
        });
    });
  };

  const clearMessages = () => {
    dispatch({ type: CLEAR_MESSAGES });
  };

  const setPrompt = (prompt) => {
    dispatch({ type: SET_PROMPT, payload: prompt });
  };

  const saveMessageContent = (message_id) => {
    dispatch({ type: SAVE_MESSAGE, payload: message_id });
  };

  const shareMessageContent = (message) => {
    MessagesService.shareMessage(message)
      .then(() => { success("Message Shared") })
      .catch(handleError)
      .finally(() => clearModal());
  };

  return (
    <MessagesContext.Provider
      value={{
        ...state,
        setPrompt,
        setMessage,
        saveMessage,
        getMessages,
        setEnhanced,
        setEnhancing,
        clearMessages,
        createMessage,
        deleteMessage,
        appendMessage,
        setGenerating,
        setConversation,
        setPropertyMessage,
        saveMessageContent,
        appendEnhancedToken,
        getConversationMessages,
        setPropertyMessagePlural,
        getPromptResponse,
        getThreadMessages,
        shareMessageContent,
        setThreadMessages,
        setRuningThread
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};
