import React, { useContext, useEffect } from "react";
import { combineComponents } from "./context";
import { CapacitorProvider } from "./context/CapacitorContext";
import { FiltersProvider } from "./context/FiltersContext";
import { OutputsProvider } from "./context/OutputsContext";
import { AvatarsProvider } from "./context/AvatarsContext";
import { PaymentSourcesProvider } from "./context/PaymentSourcesContext";
import { ConversationsProvider } from "./context/ConversationsContext";
import { MessagesProvider } from "./context/MessagesContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import { InvoicesProvider } from "./context/InvoicesContext";
import { TutorialsProvider } from "./context/TutorialsContext";
import { ProductsProvider } from "./context/ProductsContext";
import { AnalyticsProvider } from "./context/AnalyticsContext";
import { TemplatesProvider } from "./context/TemplatesContext";
import { TrainingsProvider } from "./context/TrainingsContext";
import { ModalProvider } from "./context/ModalContext";
import { AuthProvider } from "./context/AuthContext";
import { MenuProvider } from "./context/MenuContext";
import { setupIonicReact } from "@ionic/react";
import {
  AppConfigContext,
  AppConfigProvider,
} from "./context/AppConfigContext";
import { PurchasesProvider } from "./context/PurchasesContext";
import { CustomersProvider } from "./context/CustomerContext";
import { setupColor } from "./utils/appconfig";
import "@ionic/react/css/core.css";
import Main from "./Main";
import { WorkflowsProvider } from "./context/WorkflowsContext";
import { UserWorkflowsProvider } from "./context/UserWorkflowsContext";
import { TranslationsProvider } from "./context/TranslationsContext";
import { PaymentMethodsProvider } from "./context/PaymentMethodsContext";
import { StaffProvider } from "./context/StaffContext";
import { SuperFetchProvider } from "./context/SuperFetchContext";
import { OrganizationsProvider } from "./context/OrganizationsContext";
import { DocumentsProvider } from "./context/DocumentsContext";
import { CampaignsProvider } from "./context/CampaignsContext";
import { GroupsProvider } from "./context/GroupsContext";
import { SocketProvider } from "./context/SocketContext";

setupIonicReact();

const AppContextProviderWrapper = combineComponents([
  AuthProvider,
  MenuProvider,
  OrganizationsProvider,
  AvatarsProvider,
  FiltersProvider,
  OutputsProvider,
  StaffProvider,
  CustomersProvider,
  ProductsProvider,
  MessagesProvider,
  CheckoutProvider,
  CapacitorProvider,
  TrainingsProvider,
  TutorialsProvider,
  AnalyticsProvider,
  TemplatesProvider,
  InvoicesProvider,
  PurchasesProvider,
  WorkflowsProvider,
  UserWorkflowsProvider,
  ConversationsProvider,
  PaymentSourcesProvider,
  PaymentMethodsProvider,
  SuperFetchProvider,
  DocumentsProvider,
  CampaignsProvider,
  GroupsProvider,
  SocketProvider,
]);

const AppContext = () => {
  const { accent, primary, accent_secondary, firebaseConfig, getAppConfig } =
    useContext(AppConfigContext);

  useEffect(() => {
    getAppConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setupColor("primary", primary);
    setupColor("accent", accent);
  }, [primary, accent]);

  const renderApp = () => {
    if (firebaseConfig && firebaseConfig !== null) {
      return (
        <TranslationsProvider>
          <ModalProvider>
            <AuthProvider>
              <AppContextProviderWrapper>
                <Main />
              </AppContextProviderWrapper>
            </AuthProvider>
          </ModalProvider>
        </TranslationsProvider>
      );
    }
  };

  return <div className="h-100">{renderApp()}</div>;
};

const App = () => {
  return (
    <AppConfigProvider>
      <AppContext />
    </AppConfigProvider>
  );
};

export default App;
