import React, { createContext, useContext, useReducer } from "react";
import {
  SET_ENHANCED_PROMPT,
  SET_FEEDBACK,
  SET_FEEDBACK_CHANGES,
  SET_FEEDBACK_FORM,
  SET_INPUTS,
  SET_LOADING,
  SET_MESSAGES_HISTORY,
  SET_OUTPUTS,
  SET_SMALL_DEVICE,
} from "../types";
import SuperFetchReducer from "../reducers/SuperFetchReducer";
import SuperFetchService from "../services/SuperFetchService";
import { OrganizationsContext } from "../context/OrganizationsContext";

const initialState = {
  smallDevice: false,
  superFetch: {},
  inputs: [
    {
      label: "Task",
      value: "",
      type: "textarea",
      placeholder: "Write a summary about AI",
      description:
        "An example of the task component is an action verb such as write, generate, analyze, etc.",
      example: "",
      required: true,
      isValid: true,
      editable: false,
    },
    {
      label: "Topic",
      type: "textarea",
      value: "",
      placeholder: "The power of AI",
      description: "The Topic to consider when doing the task",
      required: true,
      isValid: true,
      editable: false,
    },
    {
      label: "Target Audience",
      type: "textarea",
      value: "",
      placeholder: "People who don't know how helpful IA could be.",
      description: "Describe who will use or read the output",
      required: true,
      isValid: true,
      editable: false,
    },
    {
      label: "Persona",
      type: "text",
      value: "",
      placeholder: "High School Teacher",
      example:
        "Example of a persona: 'Coaches, Content Creators, Stay-at-home moms, middle-aged men, Gen Xers, etc.'",
      description:
        "Describe the expert from whom you want the answer. This person could be famous and directly named or it could be more general like an Orthopedic Surgeon or a Fitness Expert.",
      required: true,
      isValid: true,
      editable: false,
    },
    {
      label: "Tone",
      type: "text",
      value: "",
      placeholder: "Enthusiastic",
      example:
        "Example of tone: 'Use casual, informal language that resonates with the target market'.",
      description:
        "Examples are casual, optimistic, formal, witty, dark, enthusiastic, confident, frustrated, angry, inquisitive, cheerful, etc.",
      required: true,
      isValid: true,
      editable: false,
    },
    {
      label: "Formula",
      type: "text",
      value: "",
      placeholder: "Presentation",
      description:
        "What is the output going to look like: an email, blog post, script, text, Instagram post, etc. You can also describe size, bullet point, narrative, 8th grade English (or other level or language) and other types of writing and speech.",
      required: true,
      isValid: true,
      editable: false,
    },
    {
      label: "Examples",
      type: "textarea",
      value: "",
      description:
        "Sample text(s) that you already like, such as an article written by the same persona you listed above. Anything that further explains the other categories within the SuperFetch with specifics.",
      placeholder: "Hello class today we will talk about...",
      required: false,
      isValid: true,
      editable: false,
    },
    {
      label: "Call to Action",
      type: "text",
      value: "",
      placeholder: "If you have any question, feel free to answer...",
      description:
        "The action you want the reader to perform. If there is no call action for this instruction, leave blank",
      example: "Click the link, register now, sign up today.",
      required: false,
      isValid: true,
      editable: false,
    },
    {
      label: "Manual Prompt",
      type: "textarea",
      value: "",
      placeholder: "",
      description:
        "Manual Instruction - Write your own instructor for super fetch to ensure the best output. This will be sent to our Super Fetch assistant along with the rest of the fields.",
      required: false,
      isValid: true,
      editable: false,
    },
  ],
  outputs: [],
  messages_history: null,
  loading: false,
  enhancedPrompt: "",
  feedback: {
    liked: null,
    formActive: false,
    input: {
      value: "",
      isValid: true,
    },
  },
};

export const SuperFetchContext = createContext(initialState);

export const SuperFetchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SuperFetchReducer, initialState);
  const { organization } = useContext(OrganizationsContext);

  const setOutputs = (payload) => {
    dispatch({ type: SET_OUTPUTS, payload });
  };

  const setLoading = (payload) => {
    dispatch({ type: SET_LOADING, payload });
  };

  const setFeedbackForm = (payload) => {
    dispatch({ type: SET_FEEDBACK_FORM, payload });
  };

  const setEnhancedPrompt = (payload) => {
    dispatch({ type: SET_ENHANCED_PROMPT, payload });
  };

  const changeInputValue = (newValue, inputIndex) => {
    const isValid = newValue.length > 0 ? true : false;
    const updatedInputs = [...state.inputs];
    const newInput = {
      ...state.inputs[inputIndex],
    };

    if (newInput.required) newInput.isValid = isValid;
    newInput.value = newValue;
    updatedInputs[inputIndex] = newInput;

    dispatch({ type: SET_INPUTS, payload: updatedInputs });
  };

  const getMessagesHistory = (assistant_id) => {
    SuperFetchService.getMessages(assistant_id)
      .then(res => {
        dispatch({ type: SET_MESSAGES_HISTORY, payload: res.data });
      });
  }

  const clearInputs = () => {
    const cleanedInputs = state.inputs.map((input) => {
      return {
        ...input,
        value: ''
      }
    });

    dispatch({ type: SET_INPUTS, payload: cleanedInputs });
  }

  const getSuperFetchPrompt = (outputsQty, assistant_id, prompt) => {
    const service = SuperFetchService.getOutput;
    const allInputsValid = validateInputsLength();

    if (allInputsValid) {
      setOutputs([]);
      setLoading(true);

      let messages = [prompt];

      if (outputsQty > 1) {
        messages = [];
        for (let index = 1; index <= outputsQty; index++) {
          messages.push(prompt);
        }
      }

      const data = {
        messages,
        assistant_id,
      };

      if (organization && organization.organization_id) {
        data.organization_id = organization.organization_id;
      }

      service(data).catch((err) => {
        console.log(err);
        setLoading(false);

      });
    }
  };

  const getModifiedSuperFetchPrompt = (outputsQty, assistant_id, prompt) => {
    const service = SuperFetchService.getOutput;
    const feedbackChanges = state.feedback.input.value;

    setLoading(true);
    setOutputs([]);

    const modifiedPromptStructure = `
        ${prompt}

        And at last consider this requested changes from the user:
        ${feedbackChanges}
      `;

    let messages = [modifiedPromptStructure];

    if (outputsQty > 1) {
      messages = [];
      for (let index = 1; index <= outputsQty; index++) {
        messages.push(modifiedPromptStructure);
      }
    }

    const data = {
      messages,
      assistant_id,
    };

    if (organization && organization.organization_id) {
      data.organization_id = organization.organization_id;
    }

    service(data).catch((err) => {
      setLoading(false);
      console.log(err);
    });
  };

  const validateFeedbackInput = () => {
    const inputValue = state.feedback.input.value;

    if (inputValue.length > 0) {
      return true;
    } else {
      const updatedFeedback = {
        ...state.feedback,
        input: {
          ...state.feedback.input,
          isValid: false,
        },
      };

      dispatch({ type: SET_FEEDBACK, payload: updatedFeedback });
      return false;
    }
  };

  const getPromptStructure = () => {
    const task = state.inputs[0].value;
    const topic = state.inputs[1].value;
    const target = state.inputs[2].value;
    const person = state.inputs[3].value;
    const tone = state.inputs[4].value;
    const format = state.inputs[5].value;
    const examples = state.inputs[6].value;
    const callToAction = state.inputs[7].value;

    const promptStructure = `
        -Task: ${task}

         Consider the next parameters:
          -Context: ${topic}
          -Person or profesional who will answer the task i request you: ${person}
          -Tone: ${tone}
          -Response Format: I need the response to look like ${format}
          -Examples: Give me answers as similar as possible to these examples: ${examples}
          -Call To Action: ${callToAction}
      `;

    return promptStructure;
  };

  const validateInputsLength = () => {
    let newInputs = [];
    let allInputsValid = true;

    state.inputs.map((input) => {
      const updatedInput = { ...input };

      let validInput = true;

      if (input.value.length <= 0 && input.required === true)
        validInput = false;
      updatedInput.isValid = validInput;

      if (!updatedInput.isValid) allInputsValid = false;

      newInputs.push(updatedInput);
    });

    dispatch({ type: SET_INPUTS, payload: newInputs });
    return allInputsValid;
  };

  const changeFeedbackInput = (changes) => {
    const updatedInput = {
      value: changes,
      isValid: true,
    };

    dispatch({ type: SET_FEEDBACK_CHANGES, payload: updatedInput });
  };

  const changeFeedbackLiked = (liked) => {
    const updatedFeedback = {
      ...state.feedback,
      liked,
    };

    dispatch({ type: SET_FEEDBACK, payload: updatedFeedback });
  };

  const changeInputEditable = (editable, inputIndex) => {
    const updatedInputs = [...state.inputs];
    updatedInputs[inputIndex].editable = editable;

    dispatch({ type: SET_INPUTS, payload: updatedInputs });
  };

  const getTemplatePrompt = () => {
    const inputs = [...state.inputs];
    const feedbackChanges = state.feedback.input.value;

    const taskField = `[${inputs[0].label}]`;
    const topicField = `[${inputs[1].label}]`;
    const targetField = `[${inputs[2].label}]`;
    const personField = `[${inputs[3].label}]`;
    const toneField = `[${inputs[4].label}]`;
    const formatField = `[${inputs[5].label}]`;
    const examplesField = `[${inputs[6].label}]`;
    const ctaField = `[${inputs[7].label}]`;
    const promptField = `[${inputs[8].label}]`;

    const callToActionValue =
      inputs[7].value.length > 0
        ? inputs[7].value
        : "There is no call to action on this prompt";

    const task = inputs[0].editable ? taskField : inputs[0].value;
    const topic = inputs[1].editable ? topicField : inputs[1].value;
    const target = inputs[2].editable ? targetField : inputs[2].value;
    const person = inputs[3].editable ? personField : inputs[3].value;
    const tone = inputs[4].editable ? toneField : inputs[4].value;
    const format = inputs[5].editable ? formatField : inputs[5].value;
    const examples = inputs[6].editable ? examplesField : inputs[6].value;
    const callToAction = inputs[7].editable ? ctaField : callToActionValue;
    const prompt = inputs[8].editable ? promptField : inputs[8].value;

    let templatePrompt = `
        Task: ${task}

        Consider the next parameters:
          -Topic: ${topic}
          -Target Audience: ${target}
          -Person or professional who will answer the task i request you: ${person}
          -Tone: ${tone}
          -Response Format: I need the response looks like ${format}
          -Examples: Give me answers as similar as possible to these examples: ${examples}
          -Call to Action: ${callToAction}
          -Extra Instructions: ${prompt}
      `;

    if (feedbackChanges.length > 0) {
      templatePrompt = `
          ${templatePrompt}

          And at last consider this requested changes from the user:
          ${feedbackChanges}
        `;
    }

    return templatePrompt
      .trim()
      .replace(/^\s+/gm, "")
      .replace(/^(?=\s*-)/gm, "  ");
  };

  const getTemplateFields = () => {
    const inputs = [...state.inputs];
    const fields = [];

    inputs.map((input) => {
      if (input.editable) {
        fields.push({
          name: input.label,
          label: "",
          type: "text",
          options: "",
          isValidField: true,
          isValidLabel: true,
          validOptionsLength: true,
        });
      }
    });

    return fields;
  };

  const resetFeedbackInput = () => {
    const updatedInput = {
      value: "",
      isValid: true,
    };

    changeFeedbackLiked(null);
    dispatch({ type: SET_FEEDBACK_CHANGES, payload: updatedInput });
  };

  const toggleFormActive = (active) => {
    dispatch({ type: SET_FEEDBACK_FORM, payload: active });
  };

  const toggleSmallDevice = (isSmall) => {
    dispatch({ type: SET_SMALL_DEVICE, payload: isSmall });
  };

  const enhanceSuperFetch = (data, assistant_id) => {
    const { prompt } = data;
    const request = [
      `Transform this lackluster prompt into a more effective and engaging question or statement: "${prompt}". Write the instruction to get the result, not the result itself. Consider the words within brackets will be changed to introduce user inputs in the instruction so don't modify it and use it where i can identify to insert the user inputs `,
    ];

    const requestData = {
      ...data,
      messages: request,
    };

    return new Promise((resolve, reject) => {
      SuperFetchService.getOutput({ requestData, assistant_id })
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return (
    <SuperFetchContext.Provider
      value={{
        ...state,
        setOutputs,
        setLoading,
        setEnhancedPrompt,
        setFeedbackForm,
        changeInputValue,
        getSuperFetchPrompt,
        getMessagesHistory,
        getModifiedSuperFetchPrompt,
        changeFeedbackInput,
        changeFeedbackLiked,
        changeInputEditable,
        getTemplatePrompt,
        getTemplateFields,
        toggleFormActive,
        resetFeedbackInput,
        toggleSmallDevice,
        clearInputs,
        enhanceSuperFetch,
        validateInputsLength,
      }}
    >
      {children}
    </SuperFetchContext.Provider>
  );
};
