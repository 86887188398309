import React, { useReducer, createContext } from "react";
import TranslationsReducer from "../reducers/TranslationsReducer";
import { SET_LANG } from "../types/translations";
import translations from "../utils/translations";

const initialState = {
  translations: translations,
  lang: "en",
};

export const TranslationsContext = createContext(initialState);
export const TranslationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TranslationsReducer, initialState);

  const setDefaultLang = () => {
    if(!getLocalStorageLang()) {
      saveLocalStorageLanguage(state.lang);
    }
  }

  const saveLocalStorageLanguage = (lang) => {
    localStorage.setItem("lang", lang);
  }

  const getLocalStorageLang = () => {
    return localStorage.getItem("lang");
  }

  const setLang = (lang) => {
    if (lang == state.lang) {
      return;
    }
    saveLocalStorageLanguage(lang);
    dispatch({ type: SET_LANG, payload: lang });
  };

  return (
    <TranslationsContext.Provider value={{ ...state, setLang, getLocalStorageLang, setDefaultLang }}>
      {children}
    </TranslationsContext.Provider>
  );
};
