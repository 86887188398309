import React, { useContext, useEffect } from "react";
import ConversationList from "../conversations/ConversationList";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { TemplatesContext } from "../../context/TemplatesContext";
import useTranslations from "../../hooks/useTranslations";
import TemplateDelete from "./TemplateDelete";
import { navigate } from "@reach/router";
import { ConversationsContext } from "../../context/ConversationsContext";
import ConversationModalList from "../conversations/ConversationModalList";

const TemplateCard = ({ template, hideEdit, handleApply, handleCallback }) => {
  const translations = useTranslations();
  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setTemplate, toggleFavorite } = useContext(TemplatesContext);
  const { conversation } = useContext(ConversationsContext);
  const templateId = template.template_id;

  
  const handleDelete = () => {
    modalComponent(
      translations.templates.delete,
      <TemplateDelete 
        template={template} 
        handleCancel={clearModal} 
        handleCallback={handleCallback}
      />
    );
  };

  const handleEdit = () => {
    navigate(`/templates/edit-template/${templateId}`);
  };

  const handleTemplate = () => {
    if (typeof handleApply === 'function') {
      handleApply(templateId);
    } else {
      setTemplate(template);

      if (!window.location.pathname.includes("templates")) {
        clearModal();
      }

      modalComponent(
        "Choose Conversation",
        <ConversationModalList
          size="lg"
          handleCallback={clearModal}
          link={`/templates/apply-template/${templateId}`}
        />
      );
    }
  };

  const handleFavorite = () => {
    toggleFavorite(template.template_id, handleCallback);
  };

  const renderTags = () => {
    if (template.tags !== null) {
      let tags = String(template.tags).split(",");

      return tags.map((tag, tagIndex) => {
        if (tagIndex <= 0) {
          return (
            <span key={tag} className="badge badge-pill bg-accent m-1">
              {tag}
            </span>
          );
        }
      });
    }
  };

  const renderTemplateDescription = () => {
    if (template.description !== null) {
      return (
        <>
          {String(template.description).substring(0, 100)}
          {String(template.description).length > 100 && "..."}
        </>
      );
    }
  };

  return (
    <div className="card bg-light pb-2 mb-3" style={{ height: "200px" }}>
      <div className="card-header pb-0 pt-3 bg-light border-0">
        <p
          className="bold w-100 mb-0"
          style={{ textOverflow: "ellipsis", overflow: 'hidden', maxWidth: '100%', whiteSpace: 'nowrap' }}
        >
          {template.name}
        </p>
      </div>
      <div className="card-body py-0 bg-light border-0">
        <p className="small text-muted mb-0 template-card__description">{template.description}</p>
      </div>
      <div className="card-footer bg-light border-0 pt-0">
        <div className="row align-items-end">
          <div className="col-6">
            {template.public && template.public !== null && (
              <span className="badge badge-pill bg-primary m-1">
                {translations.templates.card.public}
              </span>
            )}
            <span className="badge badge-pill bg-accent m-1">
              {template.type}
            </span>
            {renderTags()}
          </div>
          <div className="col-6 text-end">
            {(user.user_id === template.user_id ||
              (user.staff?.role === 'admin')
              ||template.editable)  &&
              !hideEdit && [
                <button
                  key="delete"
                  className="btn btn-sm btn-round m-1 btn-outline-danger"
                  title={translations.templates.card.delete}
                  onClick={handleDelete}
                >
                  <i className="fa fa-trash"></i>
                </button>,
                <button
                  key="edit"
                  className="btn btn-round btn-sm border m-1"
                  onClick={handleEdit}
                >
                  <i className="fa fa-edit"></i>
                </button>,
              ]}
            <button
              className={`btn btn-round btn-sm ${
                Array.isArray(template.favorites) &&
                template.favorites.length > 0
                  ? "btn-warning text-dark"
                  : "border"
              } m-1`}
              onClick={handleFavorite}
            >
              <i className="fa fa-star"></i>
            </button>
            <button
              className="btn btn-round btn-sm btn-primary m-1"
              onClick={handleTemplate}
            >
              <i className="fa fa-chevron-right mx-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateCard;