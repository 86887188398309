const admin = {
  users: {
    delete: "Delete Customer",
    giveAccess: "Give Access",
    generateLink: "Generate Link",
    sendEmail: "Send Email",
    recoveryPass: "Recovery Password",
    loginAccount: "Login to Account",
    loginAccountButton: "Login",
    totalPurchases: "Total Purchases",
    edit: "Edit Info",
    customer: "Customer",
    purchases: "Purchases",
    invoices: "Invoices",
    all: "All",
    titleEdit: "Edit Client",
    titleAdd: "Add Client",
    addBtn: "Add",
    editBtn: "Edit",
    saveBtn: "Save",
    cancelBtn: "Cancel",
    name: "Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone Number",
    publicity: "How do you know about bemodo ai?",
    role: "Role",
    searchBy: "Search user by name or email",
    search: "Search...",
    addUser: "Add User",
    editUser: "Edit User",
    deleteUser: "Delete User",
    deleteLabel: "Are you sure to delete the next user:",
    deleteBtn: "Delete",
  },
};

export default admin;
