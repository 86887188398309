import React, { useContext, useEffect, useState } from "react";
import TemplateGenerateCard from "../templates/TemplateGenerateCard";
import { TemplatesContext } from "../../context/TemplatesContext";
import { TranslationsContext } from "../../context/TranslationsContext";
import TemplateAsideApply from "../templates/TemplateAsideApply";
import SuperFetchAside from "../super_fetch/SuperFetchAside";
import TextareaInput from "./TextareaInput";
import { AuthContext } from "../../context/AuthContext";
import { DocumentsContext } from "../../context/DocumentsContext";
import { SuperFetchContext } from "../../context/SuperFetchContext";
import DocumentDownloadButtons from "../documents/DocumentActionsButtons";
import MobileModal from "../mobile/MobileModal";
import { CapacitorContext } from "../../context/CapacitorContext";
import TemplateDocumentToolbar from "../templates/TemplateDocumentToolbar";
import SuperFetchService from "../../services/SuperFetchService";
import { OrganizationsContext } from "../../context/OrganizationsContext";
import useTranslations from "../../hooks/useTranslations";

function DocumentTools({ createPDF, createWord, toggleAside, asideActive, sideBarWidth: width }) {
  const [promptValid, setPromptValid] = useState(true);
  const [promptValue, setPromptValue] = useState("");
  const [cardsActive, setCardsActive] = useState(true);
  const [section, setSection] = useState("templates");

  const translations = useTranslations();

  const { user } = useContext(AuthContext);
  const { platform } = useContext(CapacitorContext);
  const { setOutputs } = useContext(SuperFetchContext);
  const { organization } = useContext(OrganizationsContext);
  const { getSingleTemplate, template } = useContext(TemplatesContext);
  const { setLoadingOutputs, loadingOutputs } = useContext(DocumentsContext);

  const hideHeader = platform !== "web";
  const sideBarWidth = platform !== "web" ? '100%' : width


  const handleApplyTemplate = (template) => {
    getSingleTemplate(template.template_id);
    setCardsActive(false);
  };

  const handleChangeSection = (newSection) => {
    setSection(newSection);
  };

  const renderCards = () => {
    if (cardsActive && section === "templates") {
      return <TemplateDocumentToolbar handleApplyTemplate={handleApplyTemplate} cardsActive={cardsActive} />
    }
  };

  const renderSingleTemplate = () => {
    if (!cardsActive && section === "templates") {
      return (
        <div className="pt-3">
          <TemplateAsideApply template={template} />
        </div>
      );
    }
  };

  const renderBackBtn = () => {
    if (!cardsActive) {
      let text;
      if (section === "avatars") text = "Avatars";
      if (section === "templates") text = "Templates";

      return (
        <button
          type="button"
          className="btn btn-accent small position-absolute 
          start-0 top-0 mt-2 ms-2"
          onClick={() => setCardsActive(true)}
          style={{
            zIndex: 3,
          }}
        >
          <i className="fa fa-chevron-left me-2"></i>
          {text}
        </button>
      );
    }
  };

  const renderAsideSuperFetch = () => {
    if (section === "fetch") {
      return <SuperFetchAside />;
    }
  };

  const handleChangePrompt = (value) => {
    value.length <= 0 ? setPromptValid(false) : setPromptValid(true);
    setPromptValue(value);
  };

  const handleGeneratePrompt = async () => {
    if (promptValue.length > 0) {
      setOutputs([])
      setLoadingOutputs(true);

      const assistant_id = user.defaultAssistant?.id;
      const service = SuperFetchService.getOutput;

      const data = {
        messages: [promptValue],
        assistant_id,
      };

      if (organization && organization.organization_id) {
        data.organization_id = organization.organization_id;
      }

      service(data).catch((err) => {
        console.log(err);
        
      });

      // getSuperFetchPrompt(1, assistant_id, promptValue);
    } else {
      setPromptValid(false);
    }
  };

  const renderLoading = () => {
    if (loadingOutputs) {
      return (
        <div className=" bg-white mt-3 rounded-3 shadow  fw-bold">
          <p className="loading fs-6 m-1 text-gradient">{translations.conversations.spinner}</p>
        </div>
      );
    }
  };

  const renderAsidePrompt = () => {
    if (section === "prompt") {
      return (
        <div className="px-0 row">
          <TextareaInput
            label={"Insert your instruction!"}
            placeholder={"Write a 50 words paragraph..."}
            valid={promptValid}
            value={promptValue}
            handleChange={handleChangePrompt}
            maxHeight={"200px"}
            smallDescription
            smallContent
            bgWhite
          />

          {renderLoading()}

          <button
            className="btn btn-accent small position-relative mt-3"
            onClick={handleGeneratePrompt}
            disabled={loadingOutputs}
          >
            Generate
          </button>
        </div>
      );
    }
  };

  const renderDownloads = () => {
    if (section === "download") {
      return (
        <DocumentDownloadButtons createPDF={createPDF} createWord={createWord} />
      );
    }
  };

  const renderSectionBtns = () => {
    if (cardsActive) {
      return (
        <div className="col-12 px-0 d-flex justify-content-between mb-3">
          <button
            type="button"
            className={`btn ${section === "templates" ? "btn-primary" : "btn-outline-primary"
              } small me-2 `}
            style={{
              minWidth: 'max-content'
            }}
            onClick={() => handleChangeSection("templates")}
          >
            Templates
          </button>

          {/* <button
            type="button"
            className={`btn ${section === "fetch" ? "btn-primary" : "btn-outline-primary"
              } small me-2`}
            onClick={() => handleChangeSection("fetch")}
            style={{
              minWidth: 'max-content'
            }}
          >
            Super Fetch
          </button> */}

          <button
            type="button"
            className={`btn ${section === "prompt" ? "btn-primary" : "btn-outline-primary"
              } small me-2`}
            style={{
              minWidth: 'max-content'
            }}
            onClick={() => handleChangeSection("prompt")}
          >
            Instruction
          </button>

          <button
            type="button"
            className={`btn ${section === "download" ? "btn-primary" : "btn-outline-primary"
              } small me-2`}
            style={{
              minWidth: 'max-content'
            }}
            onClick={() => handleChangeSection("download")}
          >
            Download
          </button>
        </div>
      );
    }
  };

  const documentToolsComponent = (
    <div
      className="card bg-light h-100 d-flex flex-column p-0  border"
      style={{ overflowX: 'hidden', width: sideBarWidth }}
    >
      <div className={`w-100 d-flex card-header ${hideHeader ? "d-none" : ""}`}>
        <h4 className="w-50">Tools</h4>
      </div>
      <div
        className="card-body position-relative"
        style={{ width: '100%', overflowX: 'hidden', overflowY: 'auto' }}
      >
        <div className="row hide-scrollbar" style={{ overflowX: 'auto' }}>{renderSectionBtns()}</div>
        {renderBackBtn()}
        {renderCards()}
        {renderAsideSuperFetch()}
        {renderSingleTemplate()}
        {renderAsidePrompt()}
        {renderDownloads()}
      </div>
    </div>
  )

  const mobileVersion = (
    <div
      className={`position-absolute end-0 z-10 bottom-0 h-100 px-0`}
      style={{ width: 460 }}
    >
      <MobileModal
        title={"Tools"}
        isOpen={asideActive}
        setIsOpen={toggleAside}
      >
        {documentToolsComponent}
      </MobileModal>
    </div>
  );

  return platform !== "web" ? mobileVersion : documentToolsComponent;
}

export default DocumentTools;
