import React, { useContext } from "react";
import DynamicForm from "../global/DynamicForm";
import { ConversationsContext } from "../../context/ConversationsContext";
import useTranslations from "../../hooks/useTranslations";
import TagForm from "../global/TagForm";
import { OrganizationsContext } from "../../context/OrganizationsContext";
import { useEffect } from "react";
import { ModalContext } from "../../context/ModalContext";


const ConversationForm = ({ handleCancel }) => {
  const translations = useTranslations();
  const { spinner, conversation, saveConversation, setPropertyConversation } = useContext(ConversationsContext);
  const { organization } = useContext(OrganizationsContext)
  const { clearModal } = useContext(ModalContext)


  useEffect(() => {
    if (organization && organization.organization_id != null) {
      setPropertyConversation("organization_id", organization.organization_id);
    }
    return () => {
      clearModal()
    }
  }, [])


  const questions = [
    {
      id: "name",
      type: "text",
      required: true,
      label: translations.conversation.form.name,
    },
    {
      id: "tags",
      type: "tags",
      required: false,
    },
  ];

  return (
    <div>
      {/* <TagForm
        tagValues={conversation?.tags}
        modifier={(tags) => setPropertyConversation("tags", tags)}
      /> */}

      <DynamicForm
        spinner={spinner}
        object={conversation}
        questions={questions}
        handleCancel={handleCancel}
        saveAction={saveConversation}
        modifier={setPropertyConversation}
      />
    </div>
  );
};

export default ConversationForm;
